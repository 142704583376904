<template>
  <div>
    <!-- 空页面，用来返回上一页的 -->
  </div>
</template>

<script>
export default {
  created(){
    this.$router.go(-1)
  }
}
</script>

<style>

</style>